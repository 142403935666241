.sliderWrapper{
    display: flex;
    flex-direction: row;
    position: relative;
}

.slider{
    width: 40%;
    height: 93vh;
    margin-left: 60%;
   
    background-color: #f2f2f2;
    position: absolute;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1000;
    display: flex;
    float: right;
}

.sliderInnerWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.commentInfo{
    display: flex;
    flex-direction: row;
    width: 94%;
    margin: 8px auto;

}

.comments{
   margin-top: 10px;
   height: 650px;
   overflow-y: auto;
   border-bottom: 1px solid rgba(224,224,224,1);
}
.commentAvatar{
    padding: 5px;

}

.commentsBy{
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: space-between;

  
}

.commentsBy p{
   color: gray;
   font-size: 12px;

}

.commentedBy{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.sliderInnerWrapper form{
    display: flex;
    flex-direction: column;
  
    width: 80%;
    margin: 0px auto;
}
 
.sliderInnerWrapper form textarea{
    padding: 5px;
}

.commentSection{
    border: none;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 5px;
    width: 100%;
}



.date{
    font-size: 9px;

}
