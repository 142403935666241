.signInButton {
  width: 70%;
  height: 2.4rem;
  color: #fff;
  border-radius: 0.3rem;
  display: block;
  margin: 0 auto;
  background: rgb(49, 49, 146);
  border: 1px solid black;
  outline: none;
  /* margin-left: 100px; */
}

.cancel {
  width: 70%;
  height: 2.4rem;
  color: #fff;
  border-radius: 0.3rem;
  display: block;
  margin: 0 auto;
  background: rgb(146, 49, 49);
  border: 1px solid black;
  outline: none;
  /* margin-left: 100px; */
}

.signInButton:hover {
  background-color: blue;
  color: #fff;
}

.cancel:hover {
  background-color: rgb(255, 0, 0);
  color: #fff;
}

.signInButton a {
  color: white;
  text-decoration: none !important;
}

.signInButton a:hover {
  color: white;
}
