.acceptInvite{
    display: flex;
    height: 70vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    
}
.acceptInvite h3{
    margin-bottom: 50px;
}

.acceptInvite form{
    width: 100%;
}