.loginPage{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.togglePassword{
    position: absolute;
    top: 48%;
    right: 12.5%;
    color: gray;
}

.registration{
    color: white;

}

.loginForm{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 50%;
    background-color: #091936;
}

.loginFormHeader{
    font-size: 36px;
    font-weight:bold;
}

.loginFormText{
    margin: auto;
    text-align: center;
    padding: 2rem;
    color: white;
    line-height: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.025rem;
    font-weight: 700;
    width: 100%;
}

.registrationToggle{
   position: absolute;
   right: 12.5%;
   top: 50.5%;
   color: gray;
}

.loginForm p{
    color: gray;
}

.signInText{
    padding: 2rem;
}
.signinPolicy{
    font-size: 0.7rem;
    color: #fff;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signInButton{
    width: 60%;
    height: 70px;
    color: #fff;
    border-radius: 0.3rem;
    display: block;
    margin: 0 auto;
    background-color: #073A93;
    border: 1px solid black;
    outline: none;
}


.policy{
    color: gray;
    margin-left: 5px;
}
.loginForm input{
    border: none;
}



.loginForm input{
    padding: 15px;
    width: 60%;
    margin: 5px;
    height: 70px;
    border: 1px solid black;
    border-radius: 5px;
    outline: none;
    margin-bottom: 15px;
} 
.forgotPassword{
    font-size: 14px;
   
}

.forgetSignIn{
    margin-left: 5px;
    color: white;
    font-size: 14px;
}

.goHome{
    color: white;
    font-size: 14px;
    padding-left: 5px;
}

@media only screen and (max-width: 600px){
    .loginPage{
        display: flex;
        flex-direction: column;
    }
    .loginForm{
        width: 100%;
        background-color: #fff;
        height: 70vh;
        margin-top: -65vh;
        z-index: 1;
        border-radius: 50px 50px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    .loginFormText{
        line-height: 2rem;
        font-weight: 100;
    }
    .loginFormHeader{
        color: black;
        font-size: 20px;
        font-weight: 100;
    }
    
    .loginForm input{
        width: 100%;
        border: 1px solid rgba(224,224,224,1);
    }
    .togglePassword{
        position: absolute;
        top: 66.5%;
        left: 81%;
        color: rgba(224,224,224,1);
    }
    .signinPolicy{
        display: none;
    }
    .signInButton{
        width: 100%;
        margin-left: 5px;
    }
    .forgetPassword{
        color: black;
        font-size: 12px;
     
        padding: 20px;
    }
    
}
