/* .mainWrapper{
    height: 93.3vh;
    background-color: #edf6fa;
} */
.chatWrapper{
    margin: 10px;
    
}

.MuiPaper-rounded {
    border-radius: 0px;
}

