.uploadElement{
    font-family: Arial, Helvetica, sans-serif;
    margin: 15px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    width: 100%;
    
}


.uploadElement label{
    padding: 5px 16px;
    /* margin-right: 20px; */
    width: 33%;
}

.uploadElement input{
    border: 1px solid rgba(224,224,224,1);
    width: 44%;
    padding: 7px;

    border-radius: 8px;
}

