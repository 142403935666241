
.notification{
    position: relative;
   display: flex;
   flex-direction: row;
 
}

.notificationOuterWrapper{
    width: 500px;
    box-shadow: 5px 5px 5px 5px;
    border: 1px solid rgba(224,224,224,1);
    display: block;
    margin-left: 65%;   
    position: absolute;
    height: 500px;
   
}

.notificationOuterWrapper ul{
    width: 100%;
    color: black;
    margin: 0px;
    padding: 0px;
   
}
.Avatar{
    padding: 5px;
    margin: auto;
    height: 80px;
 
    
}
.notificationOuterWrapper ul li{
    list-style-type: none;
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    line-height: 1.3rem;
    margin: auto;
   
}

.notificationInnerWrapper{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(224,224,224,1);
  
   width: 90%;
   margin: auto;
  
}

.notificationInnerWrapper p{
    font-size: 10px;
}