.filterForm{
    width: 100%;
    color: gray;
    height: 23px;
 
}

.filterForm select{
    width: 100%;
    border: none;
    border: none;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 15px;
    padding-left: 10px;
    height: 30px;
    outline: none;
    
}

.filterForm option{
    color: gray;
}

