
.myitemOuterWrapper{
    display: flex;
    flex-direction: row; 
    height: 76vh;
    overflow-y: auto;

    background-color: inherit;
    
}

.conversationText{
    color: black;
    line-height: 10px;
    margin-left: 40px;
    margin-top: 20px;
    
    
}

.today{
    text-align: center;
    border: 1px solid gray;
    border-radius: 20px;
    width: 100px;
    margin: auto;
    padding: 5px 20px;
    color: black;
    position: relative;
    top: -20px;
    background-color: white;
}



.conversationOuterWrapper{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;

}

.conversationInnerWrapper{
    width: 50%;
    display: flex;
    flex-direction: row;
}
.message{
    margin: auto;
}

.conversationMessage{
    border: 1px solid rgba(224,224,224,1);
    border-radius: 10px;
    margin: 5px;
    width: 80%;
    padding: 15px;
}
.userImage{
    width: 7%;
    margin: auto;
}

.time{
    width: 13%;
    margin: auto;
    font-size: 12px;
  
}
.timeTwo{
    width: 13%;
    margin: auto;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
}

.chatForm{
    width:95%;
   margin: auto;
}

@media only screen and (max-width: 600px){
    .conversationOuterWrapper{
        width: 100%;
      
    }

    .conversationInnerWrapper{
        width: 80%;
        margin: auto;
    }

    .userImage{
        width: 10%;
    }
    .conversationMessage{
        width: 75%;
        
     
    }
    .time{
        width: 15%;
        font-size: 11px;
    }
    .chatForm{
        width: 96%;
        margin: auto;
    }
    .timeTwo{
        font-size: 11px;
    }
}

@media only screen and (min-width:650px) and (max-width:1080px){
    .conversationInnerWrapper{
        width: 100%;
       
    }
    .userImage{
        width: 8%;
        display: flex;
        justify-content: flex-end;
    }
    .conversationMessage{
        width: 75%;
     
    }
    .time{
        width: 15%;
        font-size: 12px;
       
    }
    .timeTwo{
        width: 15%;
    }
    .chatForm{
        width: 96%;
        margin: auto;
    }
}

.conversation_chat_message_list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: scroll;
    background-color: #e5e7ea;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 8px;
}

