.filterWrapper{
    display:flex;
    flex-direction: row;
    width:50%;
    margin: 10px;
    float: right;

}

@media only screen and (max-width:600px){
    .filterWrapper{
        width: 90%;
       
    }
}