.nascentOuterWrapper{
    height: 93vh;
    width:100%;
    font-family: Arial, Helvetica, sans-serif;
    overflow-y:  hidden !important;
}

.nascentAvatar{
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 90%;
    margin: auto;
    margin-top: 80px;
    /* margin: 80px 70px 20px 70px; */
}

.text{
    font-size: 20px;
    color: gray;
}

.nascentHeaderText{
   margin-left: 20px;
}

.nascentHeaderText h3{
    color: black;
}

.nascentInnerWrapper{
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: auto;
}
