.profileOuterWrapper{
    display: flex;
    flex-direction: row;
   
}


.profileInnerWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.linkToHome{
    text-decoration: none;
    color: black;
}
.userProfileWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;  
    font-family: Arial, Helvetica, sans-serif;
    line-height: 10px;
    margin-top: -25px;
}

.userProfileWrapper p{
    color: gray;
}

.userProfileWrapper form {
    width: 470px;
    height: auto;
}

.userProfileWrapper h3{
    color: black;
}
.userProfileWrapper form label{
    float: left;
    padding: 10px;
    width: 170px;
    height: 35px;
    margin: 8px 0;
    color: black;
}

.userProfileWrapper form .inputOne{
    height: 35px;
    width: 300px;
    border: 1px solid gray;
    border-radius: 30px;
    outline: none;
    padding: 15px;
    margin: 8px 0;
    float: right;
}


.premium{
    float:right;
    margin-top: -30px;
    margin-right:150px;
    color: #091936;
}
