.projectButton{
    outline: none !important;
    border: none;
    color: #273240;
    padding: 0px 14px;
    background-color: white;
    border: 1px solid #cbd4db;
    border-radius: 6px;
    font-size: 14px;
    height: 30px;
 
}

.projectButton:hover{
    background-color: rgba(224,224,224,9);
   
}

@media only screen and (max-width:600px){
    .projectButton{
        margin: 0px 15px;
    }
}
