.newProjectWrapper{
    width: 95%;
    margin: auto;
    padding-top: 20px;
}
.newProjectHeader{
    padding: 15px 0px 40px 0px;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}

.innerProjectWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
}
.innerProjectForm{
    float: left;
    width: 70%;
    padding: 30px;
    padding-left: 100px;
}


.innerProjectForm input{
    width: 100%;
    height: 35px;
    outline: none;
    border: 1px solid rgba(224, 224, 224,1);
    border-radius: 5px;
    padding: 10px;
}

.innerProjectForm textarea{
    width: 100%;
    outline: none;
    padding: 10px;
    border: 1px solid rgba(224, 224, 224,1);
    border-radius: 5px;
}

.innerProjectForm label{
    color: gray;
    font-size: 18px;
}

.projectForm{
    margin-bottom: 100px;
}


.redInput{

    border: 1px solid red !important;
}
@media (min-width: 300px) and (max-width: 760px) {
    .innerProjectWrapper{
        display: flex;
        flex-direction: column;
        
    }
    .innerProjectForm{
        width: 100%;
        position: absolute;
        top: 30%;
        right: 10%;
        overflow: hidden;
       
    }
    .newProjectHeader{
        padding: 20px 0px;
    }

    .projectForm{
        margin: 20px 0px;
    }

}

@media (min-width: 761px) and (max-width: 1080px){
    .innerProjectWrapper{
        display: flex;
        flex-direction: column;
        
    }
    .innerProjectForm{
        width: 90%;
        position: absolute;
        top: 40%;
        
        overflow: hidden;
       
    }
    .newProjectHeader{
        padding: 20px 0px;
    }

    .projectForm{
        margin: 20px 0px;
    }
}
