.nascentInnerText{
    width: 40%;
  
    display: flex;
    flex-direction: column;
}

.nascentInnerText p{
    float: left;
    width: 50%;
    padding: 12px;
}

.text{
    font-size: 20px;
    color: gray;
}