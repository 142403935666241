.chat_message_list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: scroll;
    background-color: #e5e7ea;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 8px;
}

