.today-style{
    text-align: center;
    display: flex;
    
}
.today-btn{
    background-color: white;
    border-radius: 10px;
    width: 150px;
}
.conversation-tab h2{
    margin: 0px;
}

.main-div{
    width: 100%;

}
.line{
    width: 100%;
    border: 0.5px solid black;
    margin-top: 20px;
  
}
.loadingMessages{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.loadMore{
    cursor : pointer;
    display: block;
    text-align:center;
    border: 1px solid rgba(224,224,224,1);
    margin: auto;
    width: 120px;
    border-radius: 5px;
   
}

.sender-wraper{
    padding: 10px 15px;
    margin-top: 30px;
}
.sender-text-box{
    display: flex;
    align-items: center
;}

 .time-style p{
     padding: 10px;
     font-size: larger;
     
 }
 .time-style{
    flex-grow: 0;
    flex-basis:inherit !important;
 }
 
 .receiver-wrapper{
     margin-left: auto !important;
     max-width: 51% !important;
 }
.receiver-wrapper-box{
    display: flex;
    align-items: center;
}
.receiver-avatar{
    margin-left: 12px !important;
}
.send-msg-area{
    width: 80%;
    margin: auto;
    background-color:rgb(131, 126, 126) ;
}
.text-area-icon{
    width: 80% !important;
    background: gray;
    margin: auto;
}
.telegram-icon{
    display: flex;
    justify-content: flex-end;
}
.telegram-icon p{
    padding:0px 5px;
}
.attach-gallery-icon{
    display: flex;
}
.attach-gallery-icon p{
    padding: 0px 5px;
}
.chatUsername{
   font-weight: bold !important;
}

.timeStamp{
    font-size: 10px;
    color: gray;
    padding-left: 10px;
}

.conversationList{
    width: 97%;
   
    margin: auto !important;
}
