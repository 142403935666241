.confDetail{
    font-family: Arial, Helvetica, sans-serif;
    margin: 15px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.confDetail label{
    padding: 5px 20px;
    margin-right: 0px;
    width: 33%;
}

.confDetail textarea{
    width: 65%;
    border: 1px solid rgba(224,224,224,1);
    outline: none;
    border-radius: 10px;
    padding: 10px;
}