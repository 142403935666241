.myitemOuterWrapper{
    display: flex;
    flex-direction: column;
    margin: 0px 30px;
}

.tableInput{
    width: 100%;
    border: none;
    height: 30px;
    
    background-color:rgb(233, 241, 245)
}

@media only screen and (max-width:600px){
    .myitemOuterWrapper{
        width: 100%;
        margin: 0px;
    }
}