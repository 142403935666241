/* .MuiInput-underline::before{
  border-bottom: none;
}

.MuiInput-underline::after{
    border-bottom: none;
}

*/

.MuiInput-underline:hover{
    border-bottom: none !important;
    border-bottom: 1px solid rgba(224,224,224,1);
} 

#chat-form textarea{
    border: none;
    background-color: inherit;

}