.modalHeader{
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
 
}

.formSection{
    margin: 10px 0px 10px 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.modalHeader a{
    float: right;
    margin-left: 300px;
}
.buttonHost{
    margin-left: 100px;
}

.closeIcon{
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
}

