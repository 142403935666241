.gray{
    color:rgba(224,224,224,1);
}

.green{
    color: green;
}

.smallScreen{
    display: none !important;

}

@media only screen and (max-width: 950px){
   .largeScreen{
       display: none !important;
   }
   .smallScreen{
       display: block !important;
   }
}