.meetingOuterWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 15px auto;
    border-radius: 20px;

    background-color: #edf6fa;
    font-family: Arial, Helvetica, sans-serif;
}

.meetingDate{
    padding: 5px;
    margin: auto;
    width: 10%;
    border-right: 1px solid blue;
    text-align: center;
}

.meetingSchedule{
    padding: 25px;
    width: 70%;
    line-height: 5px;
}

.meetingSchedule h6 {
    font-weight: bold;
}
.meetingSchedule p {
    color: gray;
}

.meetingAnchor{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    outline: none;
}


.meetingEditDelete{
    padding: 7px 15px;
    margin-right: 20px ;
    border-radius: 10px;
    background-color: #091936;
    color: white;
    outline: none !important;
    border: none;

}

.meetinCopyInfo {
    margin-top: 25px;
    padding: 7px 15px;
    margin-right: 20px ;
    border-radius: 10px;
    background-color: #091936;
    color: white;
    outline: none !important;
    border: none;
}

.meetingEditDelete:hover, :active, :focus, :visited{
    outline: none;
}



.joinButton{
    padding: 20px;
    height: 190px;
    background-color: #091936;
    border: none;
    outline: none !important;
    color: white;
    border-radius: 20px;
    width: 100px;
    float: right;
}



/* clipboard css */
.tooltip {
    position: relative;
    display: inline-block;
    font-size: 10px;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    /* bottom: 65%; */
    left: -100%;
    margin-top: -25px;
    margin-left: -5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }