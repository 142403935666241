
.replyComment{
    width: 40%;
    display: flex;
    flex-direction: column;
}

.replyComment textarea {
    border: none;
    width: 100%;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 5px;
    padding: 10px;
    margin-left: 30px;
}
.replyButton{
    width: 50%;
    margin-top: 10px;
    margin-left: 90%;

}