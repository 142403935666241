.modalHeader{
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
 
}

.formSection{
    margin: 10px 0px 10px 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.modalHeader a{
    float: right;
    margin-left: 300px;
}
.buttonHost{
    margin-left: 100px;
}
/* .projectSelect{
    display: flex;
    flex-direction: row;
    width: 100%;
} */

.label{
    padding-left: 20px;
    width: 28.5%;
}

.selectBox{
    height: 45px;
    width: 250px;
    border: none;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 7px;
    padding-left: 20px;
    margin-left: 30px;
}