.Reply{
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
}

.replyAvatar{
    padding-right: 10px;
}


.commentTime{
    font-size: 10px;
    font-weight: 100;
}