

.updatePassword h4{
    text-align: center;
    margin-bottom: 10%;
    
}

.updatePassword{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 20% auto;
    height: auto;
}

 

.updatePassword label{
    float: left;
    padding: 10px;
    width: 170px;
    height: 35px;
    margin: 8px 0;
    color: black;
}

.updatePassword .inputOne{
    height: 35px;
    width: 300px;
    border: 1px solid gray;
    border-radius: 30px;
    outline: none;
    padding: 15px;
    margin: 8px 0;
    float: right;
}

.button{
    margin-top: 10%;
}