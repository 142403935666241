.forgotPasswordWrapper{
    width: 90%;
    margin: auto;
}

.forgotPasswordWrapper form{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60vh;
    align-items: center;
    
}

.forgotPasswordForm{
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
}

.forgotPasswordForm label{
    color: black;
}
.forgotPasswordForm input{
    border: 1px solid rgba(224,224,224,1);
    border-radius: 5px ;
    padding: 10px;
}

.forgotPasswordForm button{
    border: none;
    color: white;
    background-color: #091936;
    padding: 10px;
    border-radius: 5px;
}

@media only screen and (max-width:600px){
    .forgotPasswordWrapper{
        width: 100%;
    }

    .forgotPasswordForm{
       
        width: 70%;
    }
}