h6{
    font-weight: 550;
    font-size: 14px;
}
.border-hover{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
}

.valueAssigned{
    height: 35px;
    background-color: rgb(233, 241, 245);
}

.inputState{
    display: flex;
    flex-direction: row;
    height: auto;
    border: none;
    width: 80%;
    justify-content: space-between;
    background-color: rgb(233, 241, 245);

}

.inputField{
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;
}

.emptySpace{
    height: 50vh;
    background-color: aqua;
    width: 100%;
}

.deleteIcon{
    display: flex;
    width: 2.75rem;
    flex-direction: row;
    width: 20%;
 
    justify-content: space-between;
}

.commentIcon{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
   color: gray;
}
.commentIcon p{
    font-size: 9px;
    width: auto;
    margin: auto; 
}

.selectStatus{
    border: none;
    height: 100%;
    background-color: rgb(233, 241, 245);
}


.commentIcon:hover{
    cursor: pointer;
}
