.myitemOuterWrapper{
    display: flex;
    flex-direction: row;
    margin: 0px 30px;
    height: auto;
    
}


.btn{
    border: none;
    outline: none;
    background-color: #073a93;
    color: #fff;
    padding: 5px;
    width: 150px;
}

.showDrawer{
    height: 55vh;
   
}

@media only screen and (max-width:600px){
    .myitemOuterWrapper{
        width: 100%;
        margin: 0px;
    }
}