.searchOuterWrapper {
    width: 100%;
    border: 1px solid rgba(224,224,224,1);
    background-color: white;
    border-radius: 100px;
    height: 30px;
    padding-left: 10px;
  
}

.searchOuterWrapper input{
    border: none;
    height: 25px;
    outline: none;
    margin: auto;
    width: 80%;
    padding-bottom: 3px;
}