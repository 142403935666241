.resetRequest{
   margin-top: 30px;
}

.resetRequest p{
    text-align: center;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    width: 60%;
    margin: auto;

}