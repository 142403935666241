.selectBoxOuterWrapper{
    font-family: Arial, Helvetica, sans-serif;
    /* margin: 15px 10px 20px 10px; */
    margin: 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 80%;
}

.selectBoxOuterWrapper label{
  
    margin-top : 5px;
    width: 33%;
   
}

.selectBoxModal {
    width: 55%;
    border-radius: 7px;
    border: 1px solid rgba(224,224,224,1);
    height: 40px;

   
}

.selectBoxModal select{
    width: 80%;
    border: none;
    height: 35px;
    margin-left: 20px;
    color: gray;
    letter-spacing: 0.1rem;
}


