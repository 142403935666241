.navbarInnerWrapper{
    width: 100%;
    margin-top: 9px;

}
.navbarInnerWrapper ul{
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 16px 0px 0px -10px ;
 
}
.navbarInnerWrapper ul li{
    list-style: none;
}

.navbarInnerWrapper ul li a{
   
    text-decoration: none;
    margin: 0px 10px;
    display: block;
    color: black;
    font-size: 15px;
    padding-bottom: 3px;
    color: #6f7782; 
    font-weight: 500;
}
.imgTag {
    display:flex;
    flex-direction:row;
    margin-bottom: -8px;
    width: 100%;
    
    
}

.navBarOuterWrapper{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    
}
.imgTag img{
    padding:5px 8px; 
   
    
}
.active{
    border-bottom: 3px solid #4186e0;
    color: #1890ff !important;
  
}
.projectName{
    display:flex;
    flex-direction:row ;
    margin-bottom:-28px;
    margin-top:10px;
}

.backArrow{
    font-size: 12px;
    color: #4186e0;
    font-weight: bold;
    border: none;
    height: auto;
    margin-top: 25px;

    background-color: transparent;
    border-radius: 5px;
    outline: none !important;
}

.backArrow:hover{
    /* background-color: rgba(224,224,224,1); */
    cursor: pointer;
   
}



@media only screen and (max-width: 700px) {
    .navBarOuterWrapper{
        width: 100%;
    }
    
    .navbarInnerWrapper ul li a{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 120px;
    
    }

    .navbarInnerWrapper{
        width: 100%;
        overflow-y: scroll;
    }

    
    .imageWrapper{
       display: none;
    
    }
    .imgTag{
        margin-top: 9px;
        margin-bottom: -15px;
    }
    .active{
        border-bottom: 2px solid #1890ff;
    }
   
   
    .navBarOuterWrapper{
       margin-top: 48px;
  
    }
   
    .projectName h5{
        font-size: 15px !important;
    }
}

