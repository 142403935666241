.ViewOuterWrapper{
    display: flex;
    flex-direction: column;
    
    width: 60%;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin: auto;
}

.ViewOuterWrapper h5{
    color: black;
}

.ViewButton{
    outline: none !important;
}

.accept{
    color: green;
}

.reject{
    color : red;
}