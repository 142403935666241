.myitemOuterWrapper{
    display: flex;
    flex-direction: row;
    margin: 0px 30px;
}

@media only screen and (max-width:600px){
    .myitemOuterWrapper{
        width: 100%;
        margin: 0px;
    }

    
}