.labels{
    display:flex;
    flex-direction: row;
    width: 400px;
    margin: 10px;
}

.titles{
    width: 120px;
}

.members{
    display:flex;
    flex-direction:column;
}

.modalTitle{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    
}

.closeDialog{
    padding:10px;
    cursor: pointer;
}