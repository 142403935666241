.myitemOuterWrapper{
    display: flex;
    flex-direction: row;
 
}

.buttonAndFilter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn{
    border: none;
    outline: none;
    background-color: #073a93;
    color: #fff;
    padding: 5px;
    width: 100px;
}

.filterComponent{
    width: 20%;
   
}

.tableInput{
   width: 100%;
   height: 35px;
   border:none;
   background-color:rgb(233, 241, 245);
   font-size: 12px;
  
}


.addTaskButton{
    width:96%;
    padding:10px;
    float:right;
}

.addTaskButton:hover{
    background-color: rgba(224,224,224,1);
    cursor: pointer;
}

/* @media only screen and (max-width:950px){
    .addTask{
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start;
    }
} */
