.myitemOuterWrapper{
    display: flex;
    flex-direction: row;
    height: auto;
    background-color: #edf6fa;
    width: 100%;
}
.btnWrapper{
    display: flex;
    flex-direction: row;
    
}

.hostMeetingWrapper{
    height: 200px;
    width: 200px;
    text-align: center;
    position: relative;
   
   
}


.hostMeetingWrapper button{
   padding: 60px;
   border: 1px solid rgba(224,224,224,1);
   border-radius: 10px;
   background-color: #fff;
   font-size: 20px;
   outline: none !important;
}


.expiredConferenceDiv {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 25px;
    margin-top: 10px;
}