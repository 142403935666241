.conference{
    font-family: Arial, Helvetica, sans-serif;
    margin: 15px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.conference label{
    padding: 5px 20px;
    margin-right: 13px;
    width: 32%;
}

.conference input{
    width: 44%;
    height: 40px;
    border: 1px solid rgba(224,224,224,1);
    outline: none;
    border-radius: 7px;
    padding: 10px;
    margin-right: 30px;
}

input, button {
    font: inherit;
    border: 1;
    border-radius: 0;
}


.conferenceInputWithBtn {
    display: flex; 
    grid-column: 2;
    margin: 15px 0px 20px 0px;
    justify-content:start;
}

.withButtonInput {
    flex-grow: 0;
    padding: 0.5em 0.75em;
    margin-left: 30px;
}

.withButtonInputBtn {
    padding: 0.5em 0.75em;
    background: hsl(130, 75%, 75%); 
}
