.multipleCompany{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    height: 100vh;
    align-items: center;
}

.multipleCompany h4{
    color: #091936;
}

.multipleButton{
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
}