.contentSection{
    margin-bottom: 10px;
    padding: 10px;
}
.contentSection label{
    color: gray;
}

.redInputBox{
    border: 1px solid red;
}

.contentSection input{
    width: 100%;
    height: 35px;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0px;
    outline: none;
}