.modalHeader{
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
 
}

.confEditModal{
    width: 100%;
    height: 100%;
    color: black;
}

.confSection{
    margin: 10px 0px 10px 0px;
    font-family: Arial, Helvetica, sans-serif;
    width: 50%;
    margin-top: 45px;
}

.modalHeader a{
    float: right;
    margin-left: 300px;
}
.buttonHost{
    margin-left: 100px;
}


.label{
    padding-left: 20px;
    width: 28.5%;

}

.selectBox{
    height: 43px;
    width: 248px;
    border: none;
    border: 1px solid rgba(224,224,224,1);
    border-radius: 7px;
    padding-left: 20px;
    margin-left: 30px;
}
.memberEditWrapper{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
    margin-top : 50px;
    color:black;
}

.membersEdit{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
    margin-bottom: 30px;
    margin-top: 30px;
}

.membersVal{
    margin-left: 13px;
   
    padding: 8px 0px;
}

@media only screen and (max-width: 1000px){
    .memberEditWrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    .memberEditTable{
        width: 100%;
    }

    .confSection{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .membersEdit{
        width: 100%;
    }

    .projectSelect{
        display: flex;
        flex-direction: row;
      
    }
}