.sliderWrapper{
    display: flex;
    flex-direction: row;
    position: relative;
}

.slider{
    width: 40%;
    height: 90vh;
    margin-left: 60%;
   
    background-color: #f2f2f2;
    position: absolute;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1000;
    display: flex;
    float: right;
}

/* .slider button{
    width: 40px;
    height: 20px;
    display: block;
} */

.outsideClick{
    height: 60vh;
}

.documentLog{
    padding: 10px;
    width: 100%;
}

.documentName{
    text-align: center;
   
   
}

.documentWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}