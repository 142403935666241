.loginSVG{
    width: 50%;
    float: left;
    height: 100vh;
}

.loginSVG img{
    width: 90%;
    height: 90%;
    display: block;
    justify-content: center;
    margin: auto;
   
}
@media only screen and (max-width: 600px){
    .loginSVG{
        width: 100%;
        height: 100vh;
        background-color: #091936;
        position: relative;
    }

    .loginSVG img{
        width: 100%;
        margin-top: -10px;
        height: 45vh;
        padding: 30px;
    }
}